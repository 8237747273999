import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

export default function ContactForm() {
  const form = useRef(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [phone, setPhone] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [errors, setErrors] = useState({
    name: true,
    email: true,
    message: true,
  });

  const validateEmail = (currentEmail) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !re.test(currentEmail);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    emailjs
      .send(
        'service_s0166xo',
        'template_cc90qvt',
        {
          name: name,
          email: email,
          phone: phone,
          description: description,
        },
        'user_38bh3Uv0C6vFDPmtXDlAz'
      )
      .then(
        (response) => {
          setEmailSent(true);
          setEmailSuccess(true);
        },
        (err) => {
          console.log('FAILED...', err);
          setEmailSent(true);
          setEmailSuccess(false);
        }
      );
  };

  const handleFormChange = (event) => {
    if (event.target.name === 'name') {
      setName(event.target.value);
      setErrors({ ...errors, name: event.target.value === '' });
    } else if (event.target.name === 'email') {
      setEmail(event.target.value);
      setErrors({ ...errors, email: validateEmail(event.target.value) });
    } else if (event.target.name === 'phone') {
      setPhone(event.target.value);
    } else if (event.target.name === 'description') {
      setDescription(event.target.value);
      setErrors({ ...errors, message: event.target.value === '' });
    }
  };

  return (
    <form
      ref={form}
      name='Contact form'
      method='post'
      onSubmit={handleFormSubmit}
    >
      <input
        className='form-input py-2 m-0 mb-2'
        id='name'
        name='name'
        onChange={handleFormChange}
        placeholder='Navn*'
        required
        value={name}
        rows={1}
      />
      <br />
      <input
        className='form-input py-2 m-0 mb-2'
        id='email'
        name='email'
        onChange={handleFormChange}
        placeholder='Din e-post*'
        required
        value={email}
        rows={1}
      />
      <br />
      <input
        className='form-input py-2 m-0 mb-2'
        id='phone'
        name='phone'
        onChange={handleFormChange}
        placeholder='Telefon'
        required
        value={phone}
      />
      <br />
      <textarea
        className='form-input form-textarea py-2 m-0 mb-2'
        id='description'
        name='description'
        onChange={handleFormChange}
        placeholder='Hva gjelder det?*'
        rows={4}
        required
        value={description}
      />
      <br />
      <br />
      {emailSent ? (
        emailSuccess ? (
          <p className='has-text-success'>
            Vi har mottatt din epost og vil kontakte deg snarest mulig.
          </p>
        ) : (
          <p className='has-text-danger'>
            Oisann, her gikk noe galt. Vennligst prøv igjen senere.
          </p>
        )
      ) : (
        <input
          type='submit'
          className={`btn btn-primary ${
            errors.name || errors.email || errors.message ? 'btn-disabled' : ''
          }`}
          disabled={errors.name || errors.email || errors.message}
          onClick={handleFormSubmit}
          value='Send'
        />
      )}
    </form>
  );
}
