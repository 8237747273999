import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ContactComponent from '../components/ContactComponent/ContactComponent';
import PageWrapper from '../components/PageWrapper';
import SEO from '../components/SEO/SEO';

export const ContactPageTemplate = ({ title, description, image, isPreview, contact }) => (
  <PageWrapper>
    <div className="container">
      <ContactComponent title={title} description={description} image={image} contact={contact} isPreview={isPreview}/>
    </div>
  </PageWrapper>
);

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const ContactPage = ({ data }) => {
  const { allMarkdownRemark: post } = data;
  const content = post.nodes[0].frontmatter;

  return (
    <Layout>
      <SEO
        title="Kontakt"
        description={content.description}
        image={content.image.childImageSharp ? content.image.childImageSharp.fluid.src : content.image}
      />
      <ContactPageTemplate
        title={content.title}
        description={content.description}
        image={content.image.childImageSharp ? content.image.childImageSharp.fluid : content.image}
        contact={content.contact}
        isPreview={false}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(pages/kontakt)/" } }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
          contact
        }
      }
    }
  }
`;
