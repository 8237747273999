import React from 'react'
import ContactForm from './ContactForm'
import Image from 'gatsby-image'

export default function ContactComponent({ title, description, image, contact,isPreview }) {
	return (
		<div className="tile is-ancestor">
			<div className="tile is-parent">
				<div className="tile is-child">
					<div className="columns is-vcentered is-centered is-variable is-3-desktop is-8-widescreen">
						<div className="column is-hidden-touch">
							<div className="tile is-child">
								{!isPreview ? (
									<Image fluid={image} alt="Stetind kontaktskjema" />
								) : (
									<img src={image} alt="Stetind kontaktskjema" />
								)}
							</div>
						</div>
						<div className="column">
							<div className="content">
								<h1 className="mb-4">{title}</h1>
								<p className="mb-4">{description}</p>
								<p><strong>{contact}</strong></p>
								<ContactForm />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
